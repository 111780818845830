import React from "react";
import "./App.css";
import TopContainer from "./components/TopContainer";
import AboutContainer from "./components/AboutContainer";
import SkillContainer from "./components/SkillContainer";
import "animate.css/animate.min.css";
import Jobs from "./components/JobSummary";
import MyJobs from "./components/MyJob";
import ContactMe from "./components/ContactMe";
import Header from "./components/Header";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="App">
      <Header />
      <TopContainer />
      <AboutContainer></AboutContainer>
      <SkillContainer />
      <Jobs />
      <MyJobs />
      <ContactMe />
      <Footer />
    </div>
  );
}

export default App;
