import React, { Component } from "react";
import "../scss/job-summary.scss";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import TrackVisibility from "react-on-screen";
import Carousel from "re-carousel";

export class JobSummary extends Component {
  render() {
    return (
      <div className="jobs-container" id="testimony">
        <div className="title-container">
          <h1>My Job Summary</h1>
        </div>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <div className="my-container">
              <div className="inner-left-container">
                <div className="plus-minus">
                  <h2>&#177; &nbsp;</h2>
                  <h1>
                    <TrackVisibility>
                      {({ isVisible }) =>
                        isVisible && <CountUp end={42}></CountUp>
                      }
                    </TrackVisibility>
                  </h1>
                  <h2>&nbsp;Jobs Finished</h2>
                </div>
                <div className="plus-minus">
                  <h3>Exclude Unpaid Projects</h3>
                </div>
              </div>
              <div className="inner-right-container">
                <div className="title">
                  <h1>Testimonial</h1>
                </div>
                <div className="testimonial-container">
                  <Carousel loop auto>
                    <div className="card">
                      <h1>李怀</h1>
                      <h2>Music</h2>
                      <p>
                        这首歌整体的编曲非常合理，乐器的选择也非常用心，选择了中国传统的元素，间奏的茉莉花歌曲的引用也是将本首歌整体的水平提高了。非常好听，节奏欢快！感谢我亲爱的编曲
                        <span role="img" aria-label="high5">
                          🙏
                        </span>
                        <span role="img" aria-label="high5">
                          🙏
                        </span>
                        <span role="img" aria-label="high5">
                          🙏
                        </span>
                      </p>
                    </div>
                    <div className="card">
                      <h1>Alicia</h1>
                      <h2>Film</h2>
                      <p>Dua tiga mata menatap, Kualitas video mantap</p>
                    </div>
                    <div className="card">
                      <h1>Akash Alwinism</h1>
                      <h2>Photography</h2>
                      <p>
                        Hasilnya Bagus bro hasil kamera nya mantap..semoga next
                        time bisa lebih wow lagi.. sukses buat arifotografi
                      </p>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default JobSummary;
