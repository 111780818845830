import React, { Component } from "react";
import "../scss/my-jobs.scss";

import narBinus from "../assets/images/jobs/narbinus.png";
import manayLover from "../assets/images/jobs/manay-lover.png";
import primastar from "../assets/images/jobs/primastar.png";
import ftp from "../assets/images/jobs/ftp.png";
import scallop from "../assets/images/jobs/scallop.jpg";
import findsoulmate from "../assets/images/jobs/findsoulmate.png";
import salespark from "../assets/images/jobs/salespark.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import jface from "../assets/images/jobs/jface.png";
import garibot from "../assets/images/jobs/garibot.png";
import remandar from "../assets/images/jobs/remandar.png";

export class MyJobs extends Component {
  state = {
    visible: false,
  };
  render() {
    return (
      <div className="my-jobs" id="projects">
        <div className="title-container">
          <h1>Some of My Projects</h1>
          <p>Hover to See Detail, Click to Visit</p>
        </div>
        <ScrollAnimation
          animateIn="fadeIn"
          animateOnce={true}
          afterAnimatedIn={() => {
            if (this.state.visible === false) {
              this.setState({
                visible: true,
              });
            }
          }}
        >
          <div className="my-container">
            {jobs.map((res, idx) => (
              <div key={idx}>{res}</div>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

const jobs = [
  <a
    className="job-container"
    href="https://jface.sivadira.xyz"
    without
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="image-container">
      <img src={jface} alt="" />
    </div>
    <div className="text-container">
      <h2>JfaCe</h2>
      <p>
        Inspired by StartUp Movie, JfaCe is a simple face recognition
        application implementing face-api.js
      </p>
    </div>
  </a>,
  <a
    className="job-container"
    href="https://remandar.sivadira.com"
    without
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="image-container">
      <img src={remandar} alt="" />
    </div>
    <div className="text-container">
      <h2>RemAndAr</h2>
      <p>
        RemAndAr is NAR21-1 Core Training WDH3S1 onsite case. It is a web based
        reminder made with JQuery.
      </p>
    </div>
  </a>,
  <a
    className="job-container"
    without
    rel="noopener noreferrer"
    href="#projects"
  >
    <div className="image-container">
      <img src={garibot} alt="" />
    </div>
    <div className="text-container">
      <h2>GariBot</h2>
      <p>
        A Line bot which is used to report Sunday service attendance and send
        the attendances to the person who is responsible to collect the reports.
      </p>
    </div>
  </a>,

  <a className="job-container" style={{ cursor: "default" }} href="#projects">
    <div className="image-container">
      <img src={ftp} alt="" />
    </div>
    <div className="text-container">
      <h2>FTP Server Transfer Data</h2>
      <p>
        It is an application to send data from server to an MRT unit in Jakarta
        and view the reports.
      </p>
    </div>
  </a>,
  <a className="job-container" style={{ cursor: "default" }} href="#projects">
    <div className="image-container">
      <img src={salespark} alt="" />
    </div>
    <div className="text-container">
      <h2>Sales Park Mobile</h2>
      <p>
        Sales Park is an application to check sales' activities in PT. Soles
        Multi Idea (SMID)
      </p>
    </div>
  </a>,
  <a className="job-container" style={{ cursor: "default" }} href="#projects">
    <div className="image-container">
      <img src={scallop} alt="" />
    </div>
    <div className="text-container">
      <h2>SCallOP</h2>
      <p>
        SLC Call OP (SCallOP) is an application to report/request anything to
        the supervisors in Software Laboratory Center. It includes LineBot and
        reports website.
      </p>
    </div>
  </a>,
  <a
    className="job-container"
    href="https://naar-binus.sivadira.com"
    without
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="image-container">
      <img src={narBinus} alt="" />
    </div>
    <div className="text-container">
      <h2>Naar Binus</h2>
      <p>
        This is Laravel Project for NAR20-1, it imitates NAR Binus. Naar Binus
        is a website for trainee to upload their answers, ask/answer questions
        in forum, etc.
      </p>
    </div>
  </a>,
  <a
    className="job-container"
    href="https://manay-lover.sivadira.com"
    without
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="image-container">
      <img src={manayLover} alt="" />
    </div>
    <div className="text-container">
      <h2>Manay Lover</h2>
      <p>
        This is Laravel H4S1 onsite case for NAR20-1, it was inspired by Money
        Lover application
      </p>
    </div>
  </a>,
  <a
    className="job-container"
    href="https://find-trainer-soulmate.sivadira.com"
  >
    <div className="image-container">
      <img src={findsoulmate} alt="" />
    </div>
    <div className="text-container">
      <h2>Find Trainer's Soulmate</h2>
      <p>
        It is Laravel H2BP case for NAR20-2, it is just a simple love calculator
        with some CRUD in it
      </p>
    </div>
  </a>,
  <a
    className="job-container"
    href={process.env.PUBLIC_URL + "/jobs/prim-kruskal/prim.html"}
    without
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="image-container">
      <img src={primastar} alt="" />
    </div>
    <div className="text-container">
      <h2>PrimA*</h2>
      <p>
        PrimA* simple implementation of prim and A* algorithm. Prim algorithm is
        used to generate the maze and A* algorithm is used to find the shortest
        path.
      </p>
    </div>
  </a>,
];

export default MyJobs;
