import React, { Component } from "react";
import "../scss/contact-me.scss";
import Button from "@material-ui/core/Button";
import * as Fa from "react-icons/fa";
import ScrollAnimation from "react-animate-on-scroll";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import emailjs from "emailjs-com";
export class ContactMe extends Component {
  state = {
    formData: {
      email: "",
      name: "",
      subject: "",
      content: ""
    },
    submitted: false
  };

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      emailjs.send(
        "smtp_server",
        "sivadira_web",
        {
          subject: this.state.formData.subject,
          name: this.state.formData.name,
          email: this.state.formData.email,
          content: this.state.formData.content
        },
        "user_254NLub1bYIsrGt7PkiCB"
      );
      var data = {
        email: "",
        name: "",
        subject: "",
        content: ""
      };
      setTimeout(
        () => this.setState({ submitted: false, formData: data }),
        5000
      );
    });
  };

  render() {
    const { formData, submitted } = this.state;
    return (
      <div className="contact-me-container" id="contact">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div className="my-container">
            <div className="inner-container">
              <div className="title-container">
                <h1>Contact Me</h1>
              </div>
              <div className="social-media-container">
              <a
                  className="icon"
                  href="https://www.linkedin.com/in/ari-davis-394127ba/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Fa.FaLinkedin></Fa.FaLinkedin>
                </a>
                <a
                  className="icon"
                  href="https://www.instagram.com/sivadira/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Fa.FaInstagram></Fa.FaInstagram>
                </a>
                <a
                  className="icon"
                  href="line://ti/p/ariphoto6688"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#32cd32" }}
                >
                  <Fa.FaLine></Fa.FaLine>
                </a>
                <a
                  className="icon"
                  href="https://t.me/sivadira"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#0088cc" }}
                >
                  <Fa.FaTelegram></Fa.FaTelegram>
                </a>
                <a
                  className="icon"
                  href="https://github.com/aridavis"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#333" }}
                >
                  <Fa.FaGithub></Fa.FaGithub>
                </a>
                <a
                  className="icon"
                  href="https://facebook.com/aridavis6688"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#4267B2" }}
                >
                  <Fa.FaFacebook></Fa.FaFacebook>
                </a>
              </div>
            </div>
            <div className="inner-container">
              <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                <div className="mail-form">
                  <div className="input-container">
                    <TextValidator
                      label="Email"
                      onChange={this.handleChange}
                      name="email"
                      fullWidth
                      value={formData.email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid"
                      ]}
                    />
                  </div>
                  <div className="input-container">
                    <TextValidator
                      label="Name"
                      onChange={this.handleChange}
                      name="name"
                      fullWidth
                      value={formData.name}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                  <div className="input-container">
                    <TextValidator
                      label="Subject"
                      autoComplete="off"
                      onChange={this.handleChange}
                      name="subject"
                      fullWidth
                      value={formData.subject}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                  <div className="input-container">
                    <TextValidator
                      label="Content"
                      onChange={this.handleChange}
                      name="content"
                      autoComplete="off"
                      fullWidth
                      multiline
                      rowsMax="5"
                      value={formData.content}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  </div>
                  <div
                    className="input-container"
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start"
                    }}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      variant="contained"
                      type="submit"
                      disableElevation
                      disabled={submitted}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default ContactMe;
