import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";
import "../scss/header.scss";
import { Link } from "react-scroll";

export class Header extends Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="dfjccaic">
            <Nav>
              <Link
                activeClass="active"
                className="nav-link"
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Home
              </Link>

              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                className="nav-link"
                duration={500}
              >
                About
              </Link>
              <Link
                activeClass="active"
                className="nav-link"
                to="skill"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                General Skills
              </Link>
              <Link
                activeClass="active"
                className="nav-link"
                to="coding"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Coding
              </Link>
              <Link
                activeClass="active"
                className="nav-link"
                to="testimony"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Testimony
              </Link>
              <Link
                activeClass="active"
                className="nav-link"
                to="projects"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Projects
              </Link>

              <Nav.Link
                href="https://www.sivadira.com/portfolio"
                target="_blank"
              >
                Portfolio
              </Nav.Link>

              <Link
                activeClass="active"
                className="nav-link"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Contact
              </Link>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
