import React, { Component } from "react";
import Carousel from "re-carousel";
import "../scss/about-container.scss";

import profile1 from "../assets/images/ADS_0775.jpg";
import profile2 from "../assets/images/ADS_7185.JPG";
import profile3 from "../assets/images/IMG_1629.JPG";
import IndicatorDots from "./Indicator-Dots";
import Buttons from "./Buttons";
import ScrollAnimation from "react-animate-on-scroll";

export class AboutContainer extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="flipInX" delay={300} animateOnce={true}>
          <div className="about-container" id="about">
            <div className="title-container">
              <h1>About</h1>
            </div>
            <div className="my-container">
              <Carousel loop auto widgets={[IndicatorDots, Buttons]}>
                <div className="about-me-container">
                  <div className="image-container">
                    <img src={profile2} alt="" />
                  </div>
                  <div className="text-container">
                    <div className="title-container">
                      <h2>Sivadira</h2>
                    </div>
                    <p>Hello, welcome to Sivadira. Sivadira is a simple portfolio
                    website made by Ari Davis.
                    <br />
                    Sivadira is a name standing for "AriDavis" but reversed.</p>
                  </div>
                </div>
                <div className="about-me-container">
                  <div className="text-container">
                    <div className="title-container">
                      <h2>Ari Davis</h2>
                    </div>
                    <p>My name is Ari Davis, people usually call me Ari or AA. I'm
                    a student at Bina Nusantara University and I take Computer
                    Science major. Swipe right if you are "kepo" about AA, you
                    can slide right.</p>
                  </div>
                  <div
                    className="image-container"
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src={profile3}
                      alt=""
                      style={{
                        transform: "scale(1.2) translateY(-40px)",
                        position: "relative"
                      }}
                    />
                  </div>
                </div>
                <div className="about-me-container">
                  <div className="image-container">
                    <img src={profile1} alt="" />
                  </div>
                  <div className="text-container">
                    <div className="title-container">
                      <h2>AA19-1</h2>
                    </div>
                    <p>Teaching Assistant of Software Laboratory Center since February 2019. AA19-1 is my lab initial. AA stands for my initial and 19-1 is my generation. You can see my generation's photo in the header. Working as a teaching assistant doesn't mean that we always teach people, but we do some projects to improve our skills. The project is called TPA (Test Progresif Asisten) and RIG (Research Interest Group).</p>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
      </ScrollAnimation>
    );
  }
}

export default AboutContainer;
