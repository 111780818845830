import React, { Component } from "react";
import "../scss/top-container.scss";
import Typing from "react-typing-animation";
import aa from "../assets/images/my.png";
import { Link } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";
export class TopContainer extends Component {
  render() {
    return (
      <div className="top-container" id="home">
        <div className="left-container">
          <ScrollAnimation animateIn="bounceInDown">
            <div className="content">
              <h2>Hello, I'm</h2>
              <Typing loop={true}>
                <h1>
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>S
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>i
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>v
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>a
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>d
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>i
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>r
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>a
                  <Typing.Backspace count={12} delay={2000}></Typing.Backspace>
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>A
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>r
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>i
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>&nbsp;
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>D
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>a
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>v
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>i
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>s
                  <Typing.Backspace count={12} delay={2000}></Typing.Backspace>
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>A
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>A
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>1
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>9
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>-
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>1
                  <Typing.Backspace count={10} delay={2000}></Typing.Backspace>
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>尤
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>勇
                  <Typing.Delay ms={Math.random() * 400}></Typing.Delay>成
                  <Typing.Backspace count={10} delay={2000}></Typing.Backspace>
                </h1>
              </Typing>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <button className="contact-button">CONTACT ME</button>
              </Link>
            </div>
          </ScrollAnimation>
        </div>

        <div className="right-container">
          <ScrollAnimation animateIn="bounceInUp">
            <img src={aa} alt="" />
          </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn="fadeIn">
          <div className="right-triangle"></div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default TopContainer;
