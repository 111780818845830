import React, { Component } from "react";
import "../scss/footer.scss";
export class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="inner">
          <p>
            Made by&nbsp;
            <a href="https://www.instagram.com/sivadira" title="Freepik">
              Ari Davis&nbsp;
            </a>
            with ReactJS
          </p>
          <div>
            <p>1st release : Feb 03, 2020 12:33 AM (GMT +7)</p>
            Icons made by&nbsp;
            <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
              Freepik&nbsp;
            </a>
            from&nbsp;
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com&nbsp;
            </a>
          </div>
          <p>
            Icons made by&nbsp;
            <a
              href="https://www.flaticon.com/authors/kiranshastry"
              title="Kiranshastry"
            >
              Kiranshastry&nbsp;
            </a>
            from&nbsp;
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com&nbsp;
            </a>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
