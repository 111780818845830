import React, { Component } from "react";
import "../scss/skill-container.scss";
import coding from "../assets/images/icons/coding.png";
import photography from "../assets/images/icons/camera.png";
import filmmaking from "../assets/images/icons/film.png";
import music from "../assets/images/icons/instagram-highlight-stories.png";
import ScrollAnimation from "react-animate-on-scroll";

import c from "../assets/images/icons/programming/c.png";
import java from "../assets/images/icons/programming/java.png";
import python from "../assets/images/icons/programming/python.png";
import laravel from "../assets/images/icons/programming/laravel.png";
import react from "../assets/images/icons/programming/react.png";
import visualstudio from "../assets/images/icons/programming/visualstudio.png";
import android from "../assets/images/icons/programming/android.png";
import unity from "../assets/images/icons/programming/unity.png";

import html from "../assets/images/icons/programming/html.png";
import javascript from "../assets/images/icons/programming/javascript.png";
import node from "../assets/images/icons/programming/node.png";
import sass from "../assets/images/icons/programming/sass.png";
import flutter from "../assets/images/icons/programming/flutter.png";

export class SkillContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="skill-container" id="skill">
          <ScrollAnimation animateIn="flipInY" animateOnce={true}>
            <div className="title-container">
              <h1 style={{ color: "white" }}>General Skills</h1>
            </div>
          </ScrollAnimation>

          <div className="my-container">
            <ScrollAnimation
              animateIn="bounceInLeft"
              delay={160}
              animateOnce={true}
            >
              <div className="skill">
                <div className="image-container">
                  <img src={coding} alt="" />
                </div>
                <div className="text-container">
                  <div className="title">
                    <h2>Programming</h2>
                  </div>
                  <p>
                    I usually spend my time studying and doing some programming
                    projects. I usually work on fullstack web development and
                    mobile-app development.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="bounceInLeft"
              delay={140}
              animateOnce={true}
            >
              <div className="skill">
                <div className="image-container">
                  <img src={music} alt="" />
                </div>
                <div className="text-container">
                  <div className="title">
                    <h2>Music</h2>
                  </div>
                  <p>
                    I started playing piano at the age of 6, and I have learned
                    other instruments since. Today, I am adept in playing the
                    piano, guitar, bass and drums. I have completed some
                    projects for music arrangement.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="bounceInLeft"
              delay={120}
              animateOnce={true}
            >
              <div className="skill">
                <div className="image-container">
                  <img src={photography} alt="" />
                </div>
                <div className="text-container">
                  <div className="title">
                    <h2>Photography</h2>
                  </div>
                  <p>
                    I am a self-taught photographer and I have been continuously
                    learning since 2014. I have worked as a freelance
                    photographer for my friends and schoolmates in high school.
                    Check out my Instagram to see my photos.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="bounceInLeft"
              delay={100}
              animateOnce={true}
            >
              <div className="skill">
                <div className="image-container">
                  <img src={filmmaking} alt="" />
                </div>
                <div className="text-container">
                  <div className="title">
                    <h2>Film-making</h2>
                  </div>
                  <p>
                    From directing to editing, I worked as a cinematographer for
                    my friend's school project from 2014 until 2018. These
                    skills helped my video projects at university.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div
          className="skill-container"
          style={{ backgroundColor: "#fff" }}
          id="coding"
        >
          <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
            <div className="title-container">
              <h1>Coding Skills</h1>
            </div>
          </ScrollAnimation>
          <div className="my-container">
            <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
              <div className="coding-skill">
                <img src={html} alt="HTML" srcSet="" />
                <h3>HTML</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
              <div className="coding-skill">
                <img src={javascript} alt="Javascript" srcSet="" />
                <h3>Javascript</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
              <div className="coding-skill">
                <img src={sass} alt="Sass" srcSet="" />
                <h3>Sass</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
              <div className="coding-skill">
                <img src={flutter} alt="Flutter" srcSet="" />
                <h3>Flutter</h3>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
              <div className="coding-skill">
                <img src={node} alt="NodeJS" srcSet="" />
                <h3>NodeJS</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={400}
            >
              <div className="coding-skill">
                <img src={react} alt="React" srcSet="" />
                <h3>React/Native</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
              <div className="coding-skill">
                <img src={c} alt="C/C++" srcSet="" />
                <h3>C/C++</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={100}
            >
              <div className="coding-skill">
                <img src={java} alt="Java" srcSet="" />
                <h3>Java</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={200}
            >
              <div className="coding-skill">
                <img src={python} alt="Python" srcSet="" />
                <h3>Python</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={300}
            >
              <div className="coding-skill">
                <img src={laravel} alt="Laravel" srcSet="" />
                <h3>Laravel</h3>
              </div>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={500}
            >
              <div className="coding-skill">
                <img src={visualstudio} alt="Visual Studio" srcSet="" />
                <h3>Visual Studio</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={600}
            >
              <div className="coding-skill">
                <img src={android} alt="Android Studio" srcSet="" />
                <h3>Android Studio</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="jackInTheBox"
              animateOnce={true}
              delay={700}
            >
              <div className="coding-skill">
                <img src={unity} alt="Unity" srcSet="" />
                <h3>Unity</h3>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SkillContainer;
